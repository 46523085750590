<template>
    <div
        class="floating-panel panel__left"
        :class="{'anim--open': projectData}"
        :style="`background-image:url('${delayedProjectData && delayedProjectData.projectVisual[0].hq}');`"
    >
        <div class="content">
            <p class="title">{{ projectData && projectData.title }}</p>
            <p class="client">
                <span class="name">{{ projectData && projectData.customer[0].title }}</span> <span class="sep">|</span
                ><span class="year">{{ projectData && projectData.yearOfRealization }}</span>
            </p>
            <p class="location">
                <marker-icon class="mr-3" width="20" />
                {{ $store.getters.getCurrentProjectLocation }}
            </p>
        </div>
        <!-- /content -->
    </div>
    <!-- floating-panel -->
</template>

<script>
import {ACTION_IMAGE_LOADED, ACTION_IMAGE_LOADING} from '../store';
import MarkerIcon from '@/components/icons/MarkerIcon';

export default {
    name: 'DetailLeft',
    components: {MarkerIcon},
    props: {
        backgroundImage: String,
    },
    computed: {
        projectData() {
            return this.$store.getters.getCurrentProject;
        },
    },
    asyncComputed: {
        //keep data for a second before removing, to bind image
        delayedProjectData() {
            const delay = this.projectData ? 0 : 1000;
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(this.projectData);
                }, delay);
            });
        },
    },
    mounted() {
        //delay route until image is loaded
        this.$router.beforeEach((to, from, next) => {
            //console.log("Gert: router to.params.project:", to.params.project);
            if (to.params.project) {
                this.$store.dispatch(ACTION_IMAGE_LOADING);
                // eslint-disable-next-line no-undef
                $('body').css('cursor', 'wait');
                // eslint-disable-next-line no-undef
                $('a').css('cursor', 'wait');
                this.loadImage(this.$store.state.projects.find(item => item.slug === to.params.project).projectVisual[0].hq).then(() => {
                    this.$store.dispatch(ACTION_IMAGE_LOADED);
                    // eslint-disable-next-line no-undef
                    $('body').css('cursor', 'default');
                    // eslint-disable-next-line no-undef
                    $('a').css('cursor', 'pointer');
                    next();
                });
            } else {
                next();
            }
        });
    },
    methods: {
        loadImage(url) {
            return new Promise(function(resolve, reject) {
                let img = new Image();
                img.onload = function() {
                    resolve(url);
                };
                img.onerror = function() {
                    reject(url);
                };
                img.src = url;
            });
        },
    },
};
</script>
