<template>
    <transition name="fade" appear>
        <div class="update-panel" style="margin-top: 4rem; width: 400px">
            <div>
                <h2 class="update-panel__title">Welcome!</h2>
                <p>To have access to this site, please log in to the backend first, and then refresh this page.</p>
                <p style="margin-top: 2rem">
                    <a :href="backendUrl" target="_blank">Log In</a>
                    <a href="@" @click.prevent="document.location.reload()" class="update-panel__cancel">Refresh</a>
                </p>
            </div>
        </div>
    </transition>
</template>

<script>
import {CMS_END_POINT} from "@/store";

export default {
    name: 'NotLoggedIn',
    data() {
        return {
            document,
            backendUrl: CMS_END_POINT + "/adminpanel"
        }
    },
};
</script>

<style lang="scss" scoped>
a {
    color: #eaeefa;
    background-color: #2d4275;
    margin: 1rem 1rem;
    padding: 0.6rem 2rem;
    //text-decoration: underline;
}
</style>
