<template>
    <header v-if="!this.$store.getters.isLoading" id="header">
        <ul>
            <li>
                <router-link
                    class="logo-container"
                    to="/"
                    @mouseenter.native="logoHover(true)"
                    @mouseleave.native="logoHover(false)"
                    @click.native="onLeftLogoClicked"
                    ><img width="158" height="36" src="assets/images/css/voith-logo.svg"
                /></router-link>
            </li>
            <li>
                <bootstrap-select
                    v-model="currentVehicleType"
                    :data-live-search="false"
                    :options="$store.getters.getVehicleTypes"
                    :title="$store.getters.translate('vehicle-type')"
                ></bootstrap-select>
            </li>

            <li>
                <bootstrap-select
                    v-model="currentCountry"
                    :data-live-search="true"
                    :options="$store.getters.getCountries"
                    :title="$store.getters.translate('country')"
                ></bootstrap-select>
            </li>
            <li>
                <bootstrap-select
                    v-model="currentCustomer"
                    :data-live-search="true"
                    :options="$store.getters.getCustomers"
                    :title="$store.getters.translate('customer')"
                ></bootstrap-select>
            </li>
            <li>
                <bootstrap-select
                    v-model="currentYear"
                    :data-live-search="false"
                    :options="$store.getters.getYearsOfRealization"
                    :title="$store.getters.translate('year')"
                ></bootstrap-select>
            </li>
        </ul>
        <!--{{language}}-->

        <!--<input type="radio" name="language" value="en" v-model="language"/>English-->
        <!--<input type="radio" name="language" value="zh" v-model="language"/>中文-->
    </header>
</template>

<script>
import BootstrapSelect from './BootstrapSelect';
import {
    ACTION_SET_CURRENT_COUNTRY,
    ACTION_SET_CURRENT_CUSTOMER,
    ACTION_SET_CURRENT_VEHICLE_TYPE,
    ACTION_SET_CURRENT_YEAR_OF_REALISATION,
    ACTION_SET_LANGUAGE,
    ACTION_SET_MAP_AS_FILTER,
} from '../store';

export default {
    name: 'IgwHeader',
    components: {BootstrapSelect},
    data() {
        return {
            isMounted: false,
        };
    },

    computed: {
        language: {
            get() {
                return this.$store.getters.getLanguage;
            },
            set(v) {
                this.$store.dispatch(ACTION_SET_LANGUAGE, v);
            },
        },

        currentVehicleType: {
            get() {
                return this.$store.getters.getCurrentVehicleType;
            },
            set(v) {
                if (this.$store.getters.getProjects.length > 0) {
                    this.deleteFilter();
                }
                this.$store.dispatch(ACTION_SET_CURRENT_VEHICLE_TYPE, v);
            },
        },

        currentCountry: {
            get() {
                return this.$store.getters.getCurrentCountry;
            },
            set(v) {
                if (this.$store.getters.getProjects.length > 0) {
                    this.deleteFilter();
                }
                this.$store.dispatch(ACTION_SET_CURRENT_COUNTRY, v);
            },
        },

        currentCustomer: {
            get() {
                return this.$store.getters.getCurrentCustomer;
            },
            set(v) {
                if (this.$store.getters.getProjects.length > 0) {
                    this.deleteFilter();
                }
                this.$store.dispatch(ACTION_SET_CURRENT_CUSTOMER, v);
            },
        },

        currentYear: {
            get() {
                return this.$store.getters.getCurrentYearOfRealization;
            },
            set(v) {
                if (this.$store.getters.getProjects.length > 0) {
                    this.deleteFilter();
                }
                this.$store.dispatch(ACTION_SET_CURRENT_YEAR_OF_REALISATION, v);
            },
        },
    },

    mounted() {
        this.isMounted = true;
    },

    methods: {
        onLeftLogoClicked() {
            this.$store.dispatch(ACTION_SET_CURRENT_VEHICLE_TYPE, null);
            this.$store.dispatch(ACTION_SET_CURRENT_COUNTRY, null);
            this.$store.dispatch(ACTION_SET_CURRENT_CUSTOMER, null);
            this.$store.dispatch(ACTION_SET_CURRENT_YEAR_OF_REALISATION, null);
            this.$store.dispatch(ACTION_SET_MAP_AS_FILTER, false);
        },
        deleteFilter() {
            this.$router.push({params: {project: ''}}).catch(err => {
                // Ignore the vuex err regarding navigating to the page they are already on.
                if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
                    console.log(err);
                }
            });
        },
        logoHover(hover) {
            this.$emit(hover ? 'logoMouseEnter' : 'logoMouseLeave');
        },
    },
};
</script>
