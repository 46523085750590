var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"100%","display":"block"},attrs:{"center":_vm.center,"zoom":2,"options":{
            disableDefaultUI: true,
            zoomControl: true,
            styles: _vm.styles,
            maxZoom: 12,
            minZoom: 2,
            restriction: {
                latLngBounds: {
                    // https://stackoverflow.com/questions/58762631/javascript-google-map-empty-gray-background-when-zoomed-to-zero
                    north: (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI,
                    south: (-Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI,
                    west: -180,
                    east: 180,
                },
                strictBounds: true,
            },
        }}},[_c('gmap-cluster',{ref:"mapCluster",attrs:{"styles":[
                {
                    url: 'assets/images/map/m2.png',
                    height: 50,
                    width: 50,
                    anchorText: [0, 0],
                    textColor: '#fff',
                    textSize: 13,
                    fontFamily: 'Open Sans, Segoe UI, sans-serif',
                } ],"zoomOnClick":true},on:{"click":_vm.onClusterClick}},_vm._l((_vm.markers),function(m){return _c('gmap-marker',{key:m.id,attrs:{"icon":"assets/images/map/pin1.png","width":23,"height":31,"options":{id: m.id},"position":m.position,"clickable":true,"draggable":false,"animation":2},on:{"update:position":function($event){return _vm.$set(m, "position", $event)},"click":function($event){return _vm.onMarkerClick(m.id)}}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }