<template>
    <div class="image-grid grid js--list anim--open" v-show="$store.state.isIdle">
        <div v-for="project in getRandomProjects" class="item" :style="`background-image:url('${project.projectVisual[0].hq}')`" :key="project.slug">
            <div class="content">
                <p class="title text-white">{{ project.title }}</p>
                <p class="client">
                    <span class="name">{{ project.customer[0].title }}</span>
                    <span class="sep">|</span>
                    <span class="year">{{ project.yearOfRealization }}</span>
                </p>
                <p class="location">
                    <marker-icon class="mr-2" height="14" />
                    {{ locationString(project) }}
                </p>
            </div>
            <!-- /content -->
        </div>
        <!-- /item -->
    </div>
    <!-- /image-grid -->
</template>

<script>
import {shuffle} from 'lodash';
import MarkerIcon from '@/components/icons/MarkerIcon';

export default {
    name: 'IdleState',
    components: {MarkerIcon},
    computed: {
        getRandomProjects() {
            const length = this.$store.state.projects.length;
            let items = [];
            if (length) {
                let randomNrs = [];
                for (let i = 0; i < length; i++) {
                    randomNrs.push(i);
                }
                randomNrs = shuffle(randomNrs);
                for (let i = 0; i < 13; i++) {
                    items.push(this.$store.state.projects[randomNrs[i]]);
                }
            }
            return items;
        },
    },

    asyncComputed: {
        //keep data for a second before removing, to bind image
        delayedIsIdle() {
            const delay = this.$store.state.isIdle ? 500 : 0;
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(this.$store.state.isIdle);
                }, delay);
            });
        },
    },

    methods: {
        locationString(project) {
            if (project.location.city) {
                return this.$store.getters.getLocalisedCountryTitle(project.location.country) + ', ' + project.location.city;
            } else {
                return this.$store.getters.getLocalisedCountryTitle(project.location.country);
            }
        },
    },
};
</script>
