/* eslint-disable no-console */

import {register} from 'register-service-worker';

//if (process.env.NODE_ENV === 'production' ) {
register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
        console.log('GST: ' + 'App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
    },
    registered() {
        console.log('GST: ' + 'Service worker has been registered.');
    },
    cached() {
        console.log('GST: ' + 'Content has been cached for offline use.');
    },
    updatefound() {
        console.log('GST: ' + 'New content is downloading.');
        // caches.keys().then(names => {
        // 	console.log("Gert: names:", names);
        // });
        // if (window.confirm("A new version is available, update now?")) {
        // 	const worker = registration.waiting;
        // 	if (worker) {
        // 		caches.keys().then(function(names) {
        // 			for (let name of names) caches.delete(name);
        // 		});
        // 		worker.postMessage({action: "SKIP_WAITING"});
        // 		// refresh the page or trigger a refresh programatically!
        // 		setTimeout(() => {
        // 			document.location.reload(true);
        // 		}, 2000)
        // 	}
        // }
    },
    updated(registration) {
        console.log('GST: ' + 'New content is available; please refresh.');
        document.dispatchEvent(new CustomEvent('swUpdated', {detail: registration}));
    },
    offline() {
        console.log('GST: ' + 'No internet connection found. App is running in offline mode.');
    },
    error(error) {
        console.error('Error during service worker registration:', error);
    },
});
//}
