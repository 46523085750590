import './polyfills/polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store, {ACTION_LOAD_PROJECTS} from './store';

import {sync} from 'vuex-router-sync';
import AsyncComputed from 'vue-async-computed'; //used to have promises in computed props: used to delay a prop in MainNavItem.vue
import './registerServiceWorker';

Vue.config.productionTip = false;

Vue.use(AsyncComputed);

//make jQuery globally available
const $ = require('jquery');
window.$ = $;
window.jQuery = $;

require('bootstrap');
require('bootstrap-select');

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');

sync(store, router);

// 1 - get Initial data ASAP (localstorage if exists)
store.dispatch(ACTION_LOAD_PROJECTS);

// 2 - check if localStorage is outDated
setTimeout(() => {
    store.getters.getLatestOnlineUpdate().then(res => {
        const onlineUpdated = parseFloat(res);
        const localUpdated = parseFloat(localStorage.getItem('graphQLUpdated'));

        if (localUpdated < onlineUpdated) {
            console.log('Get new online data');
            store.dispatch(ACTION_LOAD_PROJECTS, true);
        } else {
            console.log('Localstorage is still up-to-date.');
        }
    });
}, 3000);

// router.beforeEach((to, from, next) => {
// 	//console.log("Gert: tooooo:", to);
// 	next();
// });
