<template>
    <div id="map-leaflet"></div>
</template>
<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import {
    ACTION_SET_MAP_AS_FILTER,
    ACTION_SET_PROJECTS_WITHIN_BOUNDS,
    SET_CURRENT_COUNTRY,
    SET_CURRENT_CUSTOMER,
    SET_CURRENT_VEHICLE_TYPE,
    SET_CURRENT_YEAR_OF_REALISATION,
    SET_MAP_AS_FILTER,
    SET_PROJECTS,
} from '@/store';

export default {
    name: 'igw-map-leaflet-js',
    data() {
        return {
            iidUpdateBounds: -1,
            fitBoundsIid: -1,
            map: null,
            projectMarkers: [],
            overruledLabelsCoordinates: {
                // 'United Kingdom': [45.87471, 2.65],
            },
        };
    },
    // computed: {
    // 	projectMarkers() {
    // 		return this.$store.getters.getFilteredProjects.map(item => {
    // 			return {
    // 				id: item.slug,
    // 				position: {lat: parseFloat(item.location.lat), lng: parseFloat(item.location.lng)},
    // 				animation: 2, //drop
    // 			}
    // 		});
    // 	}
    //
    // },
    mounted() {
        this.$nextTick(() => {
            this.map = L.map('map-leaflet', {
                attributionControl: false,
            }).setView([0, 0], 2);
            this.map._layersMaxZoom = 8; //hack to let the cluster plugin work without a tiles map
            this.map.maxZoom = 8;
            this.map._layersMinZoom = 2;
            this.map.minZoom = 2;
            this.map.zoomControl.setPosition('bottomright');
            //Define panes above the default markerPane
            this.map.createPane('paneClusters').style.zIndex = 611;
            this.map.createPane('paneLabels').style.zIndex = 610;

            axios.get('assets/geojson/countries.geo.json').then(result => {
                //console.log("Gert: data:", result.data);
                const labelMarkers = L.markerClusterGroup({
                    maxClusterRadius: 25, //space in pixels between labels before clustering takes over
                    spiderfyOnMaxZoom: false,
                    showCoverageOnHover: false,
                    zoomToBoundsOnClick: false,
                    clusterPane: 'paneLabels',
                    animate: false,
                    iconCreateFunction: () => {
                        return L.divIcon({
                            html: '',
                            className: 'hide-clustered-labels',
                            iconSize: L.point(0, 0),
                            interactive: false,
                        });
                    },
                });

                //L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);

                let onEachCountryFeature = (feature, layer) => {
                    let labelLoc = feature.properties?.labelLoc || layer.getBounds().getCenter();
                    //add country labels
                    let labelMarker = L.marker(this.overruledLabelsCoordinates[feature.properties.name] || labelLoc, {
                        icon: L.divIcon({
                            className: '',
                            html: '',
                            iconSize: [0, 0],
                            clickable: true,
                            //iconAnchor: []
                        }),
                    });
                    console.log('label', feature.properties.name, labelLoc);
                    labelMarker.bindTooltip(feature.properties.name /*+ " " + labelLoc.lat + "," + labelLoc.lng*/, {
                        permanent: true,
                        direction: 'center',
                        className: 'country-label',
                    });
                    labelMarkers.addLayer(labelMarker);
                };

                //init map
                const geoJson = L.geoJson(result.data, {
                    attribution: '',
                    clickable: false,
                    style: {},
                    onEachFeature: onEachCountryFeature,
                }).addTo(this.map);

                geoJson.setStyle(() => {
                    return {
                        fillColor: '#F2F2F2',
                        weight: 1,
                        opacity: 0.5,
                        color: '#bbd9ec',
                        fillOpacity: 1,
                    };
                });

                this.map.addLayer(labelMarkers);

                //init projectMarkers
                this.projectMarkers = L.markerClusterGroup({
                    maxClusterRadius: 60, //space in pixels between labels before clustering takes over
                    spiderfyOnMaxZoom: false,
                    showCoverageOnHover: false,
                    zoomToBoundsOnClick: true,
                    animate: true,
                    clusterPane: 'paneClusters',
                    iconCreateFunction: cluster => {
                        return L.divIcon({
                            html: cluster.getAllChildMarkers().length,
                            className: 'cluster-icon',
                            iconSize: L.point(40, 40),
                            iconAnchor: [0, 50], // point of the icon which will correspond to marker's location
                        });
                    },
                });

                this.updateProjectMarkers();

                //update if map zooms, moves and resizes
                this.map.on('moveend', () => {
                    this.updateProjectsWithinBounds();
                    //console.log("Gert: e:", this.map.getZoom());
                });
                this.map.on('mouseup', () => {
                    //console.log("Gert: mouseup---");
                    this.$store.dispatch(ACTION_SET_MAP_AS_FILTER, true);
                });

                this.subscribeToStore();

                this.fitBounds('mounted');
            });
        });
    },

    methods: {
        onMarkerClick(slug) {
            this.$router.push('/' + slug).catch(err => {
                // Ignore the vuex err regarding navigating to the page they are already on.
                if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
                    console.log(err);
                }
            });
        },

        updateProjectsWithinBounds() {
            clearTimeout(this.iidUpdateBounds);

            this.updateProjectMarkers();

            this.iidUpdateBounds = setTimeout(() => {
                //console.log("Gert: updateProjectsWithinBounds");
                const bounds = this.map.getBounds();
                if (bounds && this.$store.state.projects.length > 0) {
                    //console.log("Gert: updateProjectsWithinBounds");
                    const withinBounds = this.$store.state.projects.filter(item =>
                        bounds.contains([parseFloat(item.location.latitude), parseFloat(item.location.longitude)]),
                    );
                    this.$store.dispatch(ACTION_SET_PROJECTS_WITHIN_BOUNDS, withinBounds);
                    // if (!isNaN(this.$store.state.currentVehicleType)) {
                    // 	this.$store.dispatch(ACTION_SET_CURRENT_VEHICLE_TYPE, NaN);
                    // }

                    //this.checkLatitude(this.$refs.map.$mapObject);
                }
            }, 200);
        },

        fitBounds(label) {
            if (this.$store.getters.getFilteredProjects && this.map && !this.$store.getters.useMapAsFilter) {
                clearTimeout(this.iidFitBounds);
                this.iidFitBounds = setTimeout(() => {
                    console.log('fitBounds ' + label + ' ' + this.$store.getters.getFilteredProjects.length);
                    if (this.$store.getters.getFilteredProjects.length > 0) {
                        let bounds = null;
                        this.$store.getters.getFilteredProjects.forEach(project => {
                            // console.log("Gert: project:", project.location);
                            if (!bounds) {
                                bounds = L.latLngBounds(
                                    [project.location.latitude, project.location.longitude],
                                    [project.location.latitude, project.location.longitude],
                                );
                            }
                            bounds.extend([project.location.latitude, project.location.longitude]);
                        });
                        // console.log("fitBounds", bounds);
                        this.map.flyToBounds(bounds, {duration: 0.5, maxZoom: 5});
                    } else {
                        this.map.setZoom(2);
                    }
                }, 200);
            }
        },

        subscribeToStore() {
            this.$store.subscribe(mutation => {
                if (mutation.type === SET_PROJECTS) {
                    this.updateProjectMarkers();
                    this.fitBoundsIid = setTimeout(() => {
                        this.fitBounds('projects');
                    }, 800);
                }
                if (
                    mutation.type === SET_CURRENT_VEHICLE_TYPE ||
                    mutation.type === SET_CURRENT_COUNTRY ||
                    mutation.type === SET_CURRENT_CUSTOMER ||
                    mutation.type === SET_CURRENT_YEAR_OF_REALISATION
                ) {
                    this.updateProjectMarkers();

                    clearTimeout(this.fitBoundsIid);
                    this.fitBoundsIid = setTimeout(() => {
                        if (!this.$store.getters.useMapAsFilter) {
                            this.fitBounds('dropdowns');
                        }
                    }, 100);
                }

                if (mutation.type === SET_MAP_AS_FILTER) {
                    if (!this.$store.state.useMapAsFilter) {
                        this.updateProjectMarkers();
                        this.fitBounds('map filter');
                    }
                }
            });
        },

        updateProjectMarkers() {
            this.projectMarkers.clearLayers();
            const projectMarkerArray = [];
            this.$store.getters.getFilteredProjects.forEach(project => {
                let icon = L.icon({
                    iconUrl: 'assets/images/map/pin1.png',
                    iconSize: [26, 35], // size of the icon
                    iconAnchor: [13, 35], // point of the icon which will correspond to marker's location
                    popupAnchor: [0, -18], // point from which the popup should open relative to the iconAnchor
                });
                let projectMarker = L.marker(new L.LatLng(parseFloat(project.location.latitude), parseFloat(project.location.longitude)), {
                    slug: project.slug,
                    icon,
                });

                projectMarker.on('click', e => {
                    this.onMarkerClick(e.target.options.slug);
                });
                projectMarkerArray.push(projectMarker);
            });
            this.projectMarkers.addLayers(projectMarkerArray);

            this.map.addLayer(this.projectMarkers);
        },
    },
};
</script>

<style type="scss">
#map-leaflet {
    z-index: 0;
    height: 100%;
    width: 100%;
    background-color: #bbd9ec;
}

.country-label {
    /*font-family: 'Arial Narrow', sans-serif;*/
    font-size: 14px;
    color: #2d4275;
    background: rgba(255, 255, 255, 0);
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 0;
}

.cluster-icon {
    border-radius: 25px;
    border: white 2px solid;
    background-color: #2d4275;
    text-align: center;
    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    padding-top: 8px;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}
</style>
