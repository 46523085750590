<template>
    <transition name="fade">
        <div v-show="!hide || $store.state.imageLoading" class="preloader" :class="{'preloader--boxed': $store.state.imageLoading}">
            <!--  add "preloader--boxed" as a class to show secondary preloader -->
            <div class="spinner loader-mz">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'IgwPreloader',
    asyncComputed: {
        hide() {
            return this.$store.getters.getProjects.length > 0;
        },
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 2.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
	 {
    opacity: 0;
}
</style>
