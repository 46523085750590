<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8995 17.3137L20.1421 21.5564L21.5564 20.1421L17.3137 15.8995L21.5564 11.6569L20.1421 10.2427L15.8995 14.4853L11.6569 10.2426L10.2426 11.6569L14.4853 15.8995L10.2426 20.1422L11.6569 21.5564L15.8995 17.3137Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'closeIcon',
};
</script>

<style scoped></style>
