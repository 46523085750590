<template>
    <div>
        <select class="selectpicker" :title="title" :data-live-search="dataLiveSearch">
            <option v-for="(option, index) in options" :key="index" :value="option.id" :selected="option.selected">
                {{ option.title }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'BootstrapSelect',
    props: {
        title: {
            required: true,
            type: String,
        },
        dataLiveSearch: {
            type: Boolean,
            default: false,
        },
        options: {
            required: true,
            type: Array,
            description: 'Array of Objects with props label, value, selected',
        },
        value: {
            description: 'Use v-model to bind your data',
        },
    },

    data() {
        return {
            JQuerySelectPicker: '',
            hoveringCloseButton: false,
            isClosing: false,
        };
    },

    mounted() {
        // eslint-disable-next-line no-undef
        this.JQuerySelectPicker = $(this.$el).find('.selectpicker');

        this.JQuerySelectPicker.selectpicker().on('loaded.bs.select', $e => {
            $e.currentTarget.nextSibling.addEventListener('mousemove', e => {
                //console.log(this.hoveringCloseButton);
                this.hoveringCloseButton = e.currentTarget.clientWidth - e.offsetX < 50;
            });
        });

        this.JQuerySelectPicker.selectpicker()
            .on('hidden.bs.select', e => {
                if (!this.isClosing) {
                    if (this.value && this.hoveringCloseButton) {
                        this.onCloseClicked();
                    } else {
                        //IE hack
                        if (!e.currentTarget.selectedOptions) {
                            if (e.target.value) {
                                this.$emit('input', e.target.value);
                            }
                        }
                        if (e.currentTarget.selectedOptions.length > 0) {
                            this.$emit('input', e.currentTarget.selectedOptions[0].value);
                        }
                    }
                }
            })
            .on('show.bs.select', () => {
                if (!this.isClosing && this.value && this.hoveringCloseButton) {
                    this.isClosing = true;
                    this.JQuerySelectPicker.selectpicker('toggle');
                    this.onCloseClicked();
                }
            });

        this.updateValue(this.value);
    },

    methods: {
        updateValue(newValue) {
            if (this.value !== newValue) {
                //ugly jquery timeout
                setTimeout(() => {
                    // eslint-disable-next-line no-undef
                    $(this.$el)
                        .find('.selectpicker')
                        .selectpicker('val', newValue);
                    this.$emit('input', newValue);
                }, 10);
                if (newValue) {
                    // eslint-disable-next-line no-undef
                    $(this.$el)
                        .find('.bootstrap-select')
                        .addClass('selected-item');
                } else {
                    // eslint-disable-next-line no-undef
                    $(this.$el)
                        .find('.bootstrap-select')
                        .removeClass('selected-item');
                }
            }
        },
        onCloseClicked() {
            this.updateValue(null);
            this.isClosing = false;
            //this.JQuerySelectPicker.selectpicker('refresh');
            //console.log("Gert: :", $(this.$el).find(".dropdown-toggle"));
            setTimeout(() => {
                // eslint-disable-next-line no-undef
                $(this.$el)
                    .find('.dropdown-toggle')
                    .attr('aria-expanded', 'false');
            }, 40);
        },
    },

    watch: {
        value: function(newValue, oldValue) {
            //console.log(newValue, oldValue);
            if (newValue !== oldValue) {
                this.updateValue(newValue);
            }
        },
    },

    updated() {
        this.JQuerySelectPicker.selectpicker('refresh');
    },

    destroyed() {
        this.JQuerySelectPicker.off().selectpicker('destroy');
    },
};
</script>
