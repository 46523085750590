<template>
    <div class="floating-panel panel__right js--detail" :class="{'anim--open': $store.getters.getCurrentProject}">
        <div class="img-holder">
            <img v-if="delayedProjectData && delayedProjectData.projectCadDrawing.length > 0" :src="delayedProjectData.projectCadDrawing[0].hq" alt="" />
        </div>

        <div style="min-height: 40px" v-if="delayedProjectData && delayedProjectData.projectCadDrawing.length === 0">
            <!--spacer to get room for the close button if there's no image -->
        </div>

        <div class="content">
            <div class="prompt__read-more">
                <a class="button js--readmore" href="#"><i class="material-icons shake animated infinite a-1">keyboard_arrow_down</i></a>
            </div>
            <h2 v-if="delayedProjectData && delayedProjectData.leadText" class="title">
                {{ delayedProjectData.leadText }}
            </h2>

            <table class="table-specs">
                <tbody>
                    <tr v-if="projectData && projectData.vehicleType.length > 0">
                        <td>{{ $store.getters.translate('type-of-vehicle') }}</td>
                        <td>
                            <span class="badg" :style="`background-color: ${projectData.vehicleType[0].backgroundColor}`">{{
                                projectData.vehicleType[0].title
                            }}</span>
                        </td>
                    </tr>
                    <tr v-if="projectData && projectData.operator">
                        <td>{{ $store.getters.translate('operator') }}</td>
                        <td>{{ projectData.operator }}</td>
                    </tr>
                    <tr v-if="projectData && projectData.numberOfDeliveredGearboxes">
                        <td>{{ $store.getters.translate('project-quantity') }}</td>
                        <td>{{ projectData.numberOfDeliveredGearboxes | formatInteger }}</td>
                    </tr>
                </tbody>
            </table>

            <h2 class="subtitle" v-if="hasTechnicalData">
                {{ $store.getters.translate('technical-data') }}
            </h2>

            <table class="table-data" v-if="hasTechnicalData">
                <tbody>
                    <tr v-if="projectData && projectData.motorContinousPower">
                        <td>{{ $store.getters.translate('motor-continous-power') }}</td>
                        <td>{{ projectData.motorContinousPower | formatInteger }} kW</td>
                    </tr>
                    <tr v-if="projectData && projectData.motorContinousSpeed">
                        <td>{{ $store.getters.translate('motor-continous-speed') }}</td>
                        <td>{{ projectData.motorContinousSpeed | formatInteger }} rpm</td>
                    </tr>
                    <tr v-if="projectData && projectData.maxMotorSpeed">
                        <td>{{ $store.getters.translate('max-motor-speed') }}</td>
                        <td>{{ projectData.maxMotorSpeed | formatInteger }} rpm</td>
                    </tr>
                    <tr v-if="projectData && projectData.shortCircuitTorque">
                        <td>{{ $store.getters.translate('short-circuit-torque') }}</td>
                        <td>{{ projectData.shortCircuitTorque | formatInteger }} Nm</td>
                    </tr>
                    <tr v-if="projectData && projectData.maxSpeed">
                        <td>{{ $store.getters.translate('max-speed') }}</td>
                        <td>{{ projectData.maxSpeed | formatInteger }} km/h</td>
                    </tr>
                    <tr v-if="projectData && projectData.axleLoad">
                        <td>{{ $store.getters.translate('axle-load') }}</td>
                        <td>{{ projectData.axleLoad | formatInteger }} kg</td>
                    </tr>
                    <tr v-if="projectData && projectData.gearRatio">
                        <td>{{ $store.getters.translate('gear-ratio') }}</td>
                        <td>{{ projectData.gearRatio | formatNumber('0.000') }}</td>
                    </tr>
                </tbody>
            </table>

            <h2 class="subtitle" v-if="hasWeightData">{{ $store.getters.translate('weights') }}</h2>

            <table class="table-data" v-if="hasWeightData">
                <tbody>
                    <tr v-if="projectData && projectData.gearUnit">
                        <td>{{ $store.getters.translate('gear-unit') }}</td>
                        <td>{{ projectData.gearUnit | formatInteger }} kg</td>
                    </tr>
                    <tr v-if="projectData && projectData.inputCoupling">
                        <td>{{ $store.getters.translate('input-coupling') }}</td>
                        <td>{{ projectData.inputCoupling | formatInteger }} kg</td>
                    </tr>
                    <tr v-if="projectData && projectData.reactionRod">
                        <td>{{ $store.getters.translate('reaction-rod') }}</td>
                        <td>{{ projectData.reactionRod | formatInteger }} kg</td>
                    </tr>
                </tbody>
            </table>

            <div v-if="projectData && projectData.bodyText" v-html="projectData.bodyText.content" />

            <div class="ref-number" v-if="projectData && projectData.referenceNumber">
                {{ projectData.referenceNumber }}
            </div>
        </div>
        <!-- /content -->
    </div>
    <!-- /floating-panel -->
</template>

<script>
import numeral from 'numeral';

export default {
    name: 'DetailRight',

    computed: {
        projectData() {
            return this.$store.getters.getCurrentProject;
        },
        hasTechnicalData() {
            return (
                this.projectData &&
                (this.projectData.motorContinousPower ||
                    this.projectData.motorContinousSpeed ||
                    this.projectData.maxMotorSpeed ||
                    this.projectData.shortCircuitTorque ||
                    this.projectData.maxSpeed ||
                    this.projectData.axleLoad ||
                    this.projectData.gearRatio)
            );
        },

        hasWeightData() {
            return this.projectData && (this.projectData.gearUnit || this.projectData.inputCoupling || this.projectData.reactionRod);
        },
    },

    asyncComputed: {
        //keep data for a second before removing, to bind image
        delayedProjectData() {
            const delay = this.projectData ? 0 : 1000;
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(this.projectData);
                }, delay);
            });
        },
    },

    filters: {
        formatInteger: function(value) {
            return numeral(value).format('0,');
        },

        formatNumber: function(value, format) {
            return numeral(value).format(format);
        },
    },

    mounted() {
        // function sn__readMore() {
        //
        // 	let $detail = $('.js--detail');
        // 	let $button = $('.prompt__read-more');
        //
        // 	$($button).on("click", function () {
        // 		$($detail).animate({
        // 			scrollTop: 400,
        // 		}, 600);
        //
        // 	});
        //
        // 	$($detail).scroll(function () {
        // 		if ($(this).scrollTop() > 10) {
        // 			$($detail).removeClass('collapsed');
        //
        // 		} else {
        // 			$($detail).addClass('collapsed');
        // 		}
        // 	});
        // }
        //
        // sn__readMore();
    },

    methods: {},
};
</script>
