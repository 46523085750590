<template>
    <div class="h-full" v-if="isLoggedIn">
        <igw-preloader />

        <div v-if="!$store.getters.isLoading" id="wrapper">
            <div class="container-fluid">
                <div class="main-holder">
                    <igw-header @logoMouseEnter="showInfoBox = true" @logoMouseLeave="showInfoBox = false" />

                    <section id="main" class="row no-gutters">
                        <div :class="{'col-lg-6 ': true}" class="col-16 side__left">
                            <igw-map-google v-if="isGoogleMap === isOnline" />
                            <igw-map-leaflet-js v-if="isGoogleMap !== isOnline" />
                            <detail-left />
                        </div>
                        <!-- /col -->

                        <div v-show="true" class="col-12 col-lg-6 side__right">
                            <idle-state />

                            <filter-grid />

                            <detail-right />
                        </div>
                        <!-- /col -->
                    </section>
                    <!-- /row -->
                </div>
                <!-- /main-holder -->
            </div>
            <!-- /container -->
        </div>
        <!-- /wrapper -->

        <transition name="fade">
            <router-link v-show="$store.getters.getCurrentProject" class="close-button" title="close" to="/">
                <close-icon class="close-button__icon" />
            </router-link>
        </transition>

        <transition name="fade">
            <div v-if="updateExists" class="update-panel">
                <div>
                    <h2 class="update-panel__title">An update is available. Update?</h2>
                    <div>
                        <button class="update-panel__cancel" @click.prevent="updateExists = false">Later</button>
                        <button @click.prevent="refreshApp">Yes</button>
                    </div>
                </div>
            </div>
        </transition>

        <div v-show="!$store.getters.isLoading" class="lang-nav">
            <ul>
                <li>
                    <span class="lang-active" @click="languagePanelOpen = !languagePanelOpen">
                        <svg
                            class="lang-active__icon"
                            fill="currentColor"
                            width="18"
                            height="18"
                            xmlns="http://www.w3.org/2000/svg"
                            xml:space="preserve"
                            viewBox="0 0 32 32"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M16 30c7.7 0 14-6.3 14-14S23.7 2 16 2 2 8.3 2 16s6.3 14 14 14zm1-2c.5 0 1-.1 1.4-.2.4-.5.8-1.1 1.1-1.9.2-.4.4-.8.5-1.2-1 .2-2 .2-3.1.3v3zm-2 0v-3c-1.1 0-2.1-.1-3.1-.3.2.4.3.8.5 1.2.4.8.8 1.4 1.2 1.9.4 0 .9.1 1.4.2zm2-5c1.3 0 2.6-.2 3.8-.4.3-1.2.5-2.6.6-4.1-1.4.2-2.9.4-4.4.5v4zm-2-4v4c-1.3 0-2.6-.2-3.8-.4-.3-1.2-.5-2.6-.6-4.1 1.4.2 2.9.4 4.4.5zm2-2c1.6-.1 3.1-.3 4.4-.6 0-1.8-.2-3.5-.5-5-1.2.3-2.5.5-4 .6v5zm-2-5v5c-1.6-.1-3.1-.3-4.4-.6 0-1.8.2-3.5.5-5 1.2.3 2.5.5 3.9.6zm2-2c1.3-.1 2.4-.3 3.5-.6-.3-.8-.5-1.6-.9-2.3-.9-1.9-1.9-2.7-2.6-3V10zm-2-5.9V10c-1.3-.1-2.4-.3-3.5-.6.3-.8.6-1.6.9-2.3.9-1.9 1.9-2.7 2.6-3zm10.7 19c-1.1 1.5-2.6 2.7-4.2 3.6.4-.7.7-1.6.9-2.5 1.2-.3 2.3-.6 3.3-1.1zm0-2.2c-.8.4-1.7.8-2.7 1.1.3-1.3.4-2.6.5-4.1 1.9-.6 3.4-1.4 4.6-2.3v.4c0 1.3-.2 2.6-.6 3.7-.6.4-1.1.8-1.8 1.2zm-.7-5.7c-.5.2-1 .5-1.6.7 0-1.8-.2-3.5-.6-5.1 1.1-.5 2.1-1.1 2.8-1.8.9 1.2 1.6 2.7 2 4.2-.4.5-1.2 1.2-2.6 2zm-2.2-6.7c-.2.1-.3.2-.5.2-.5-1.5-1-2.7-1.7-3.8 1.4.6 2.6 1.4 3.7 2.4-.3.4-.8.8-1.5 1.2zM10.6 26.7c-.4-.7-.7-1.6-.9-2.5-1.2-.3-2.3-.7-3.3-1.1 1.1 1.5 2.5 2.8 4.2 3.6zm-2-8.8c.1 1.4.2 2.8.5 4.1-1-.3-1.9-.7-2.7-1.1-.7-.4-1.3-.8-1.7-1.2-.5-1.1-.7-2.4-.7-3.7v-.4c1.1 1 2.7 1.8 4.6 2.3zm.5-7.2c-.3 1.6-.5 3.3-.6 5.1L7 15.2c-1.4-.7-2.2-1.4-2.6-2.1.4-1.5 1.1-3 2-4.2.6.7 1.6 1.3 2.7 1.8zm2.2-5.8c-.7 1-1.2 2.3-1.7 3.8-.1-.1-.3-.1-.4-.2-.7-.4-1.2-.8-1.5-1.1 1-1.1 2.3-1.9 3.6-2.5z"
                                clip-rule="evenodd"
                            />
                        </svg>

                        {{ languageLabel }}
                    </span>

                    <div v-if="languagePanelOpen" class="triangle"></div>
                    <ul v-if="languagePanelOpen">
                        <li>
                            <input id="en" v-model="language" name="language" type="radio" value="en" />
                            <label for="en">English</label>
                        </li>
                        <li>
                            <input id="zh" v-model="language" name="language" type="radio" value="zh" />
                            <label for="zh">中文</label>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <!--/lang-nav-->

        <div class="info-box" v-show="showInfoBox">
            {{ version }} | Last data update: {{ latestUpdateDate }} | Data downloaded: {{ imagesPreloadPercentage }}% |
            {{ isOnline ? 'Online' : 'OFFLINE' }}
            <!--			<input type="checkbox" v-model="isGoogleMap">-->
        </div>
    </div>
    <!-- vue template wrapper-->
    <NotLoggedIn v-else/>
</template>
<script>
import IgwPreloader from './components/IgwPreloader';
import IgwHeader from './components/IgwHeader';
import DetailLeft from './components/DetailLeft';
import IdleState from './components/IdleState';
import FilterGrid from './components/FilterGrid';
import DetailRight from './components/DetailRight';
import IgwMapGoogle from './components/IgwMapGoogle';
import {
    ACTION_SET_CURRENT_COUNTRY,
    ACTION_SET_CURRENT_CUSTOMER,
    ACTION_SET_CURRENT_VEHICLE_TYPE,
    ACTION_SET_CURRENT_YEAR_OF_REALISATION,
    ACTION_SET_IDLE,
    ACTION_SET_LANGUAGE,
    ACTION_SET_SMALL_IDLE,
    ACTION_TOGGLE_PRELOAD_IMAGE,
    IDLE_TIME_IN_SECONDS,
} from './store';
import updateServiceWorker from './mixins/updateServiceWorker';
import IgwMapLeafletJs from '@/components/IgwMapLeafletJs';
import axios from 'axios';
import CloseIcon from '@/components/icons/closeIcon';
import NotLoggedIn from "@/components/NotLoggedIn.vue";

export default {
    components: {
        NotLoggedIn,
        CloseIcon,
        IgwMapLeafletJs,
        IgwMapGoogle,
        DetailRight,
        FilterGrid,
        IdleState,
        DetailLeft,
        IgwHeader,
        IgwPreloader,
    },
    mixins: [updateServiceWorker],
    data() {
        return {
            version: 'v2.09', //update each time a new version goes online. this makes it easy to check if the service worker has loaded the last version.
            isLoggedIn: true,
            $idleIid: 0,
            $idleGrid: null,
            $filterGrid: null,
            openClass: 'anim--open',
            hiddenClass: 'd-none',
            languagePanelOpen: false,
            imagesPreloadPercentage: 0,
            isOnline: true,
            isGoogleMap: true,
            showInfoBox: false,
        };
    },

    async mounted() {

        //check twig template if user is logged in at Craft CMS
        // try {
        //     const response = await axios.get(`${CMS_END_POINT}/is-logged-in?${Math.random()}`);
        //     this.isLoggedIn = response?.data;
        //     //console.log("this.isLoggedIn:", this.isLoggedIn);
        //
        // } catch (e) {
        //     console.log("e", e);
        //     this.isLoggedIn = false;
        //     // if (document.location.hostname)
        // }

        console.log("this.isLoggedIn:", this.isLoggedIn);

        //download map for offline use.
        axios.get('assets/geojson/countries.geo.json');

        this.isOnline = navigator.onLine;

        window.addEventListener('online', () => {
            this.isOnline = true;
        });

        window.addEventListener('offline', () => {
            this.isOnline = false;
        });

        document.body.addEventListener('click', () => this.onGlobalClick(), true);

        /*=========================================
=            in out animations            =
=========================================*/

        // eslint-disable-next-line no-undef
        this.$idleGrid = $('.image-grid');
        // eslint-disable-next-line no-undef
        this.$filterGrid = $('.filter-grid');

        //this.$filterGrid.addClass(this.openClass);

        setTimeout(() => {
            this.onGlobalClick();
        }, 1000);
    },

    watch: {
        lastUpdate() {
            this.preloadImages();
        },
    },

    computed: {
        lastUpdate() {
            return this.$store.state.lastUpdate;
        },

        language: {
            get() {
                return this.$store.getters.getLanguage;
            },
            set(v) {
                this.$store.dispatch(ACTION_SET_LANGUAGE, v);
                this.languagePanelOpen = false;
            },
        },

        languageLabel() {
            if (this.$store.getters.getLanguage === 'en') {
                return 'English';
            } else {
                return '中文';
            }
        },

        latestUpdateDate() {
            return this.$store.state.lastUpdate
                ? this.$store.state.lastUpdate.toLocaleDateString() + ', ' + this.$store.state.lastUpdate.toLocaleTimeString()
                : ' (Refresh to get date)';
        },
    },

    methods: {
        onGlobalClick() {
            clearTimeout(this.idleIid);
            clearTimeout(this.smallIdle);

            if (this.$store.state.isIdle) {
                this.showFilter();
                this.$store.dispatch(ACTION_SET_IDLE, false);
                this.$store.dispatch(ACTION_SET_CURRENT_VEHICLE_TYPE, null);
                this.$store.dispatch(ACTION_SET_CURRENT_COUNTRY, null);
                this.$store.dispatch(ACTION_SET_CURRENT_CUSTOMER, null);
                this.$store.dispatch(ACTION_SET_CURRENT_YEAR_OF_REALISATION, null);
                this.$router.push({path: '/'}).catch(err => {
                    // Ignore the vuex err regarding navigating to the page they are already on.
                    if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
                        console.log(err);
                    }
                });
            }
            this.idleIid = setTimeout(() => {
                this.showIdle();
                this.$store.dispatch(ACTION_SET_IDLE, true);
                this.$store.dispatch(ACTION_SET_CURRENT_VEHICLE_TYPE, null);
                this.$store.dispatch(ACTION_SET_CURRENT_COUNTRY, null);
                this.$store.dispatch(ACTION_SET_CURRENT_CUSTOMER, null);
                this.$store.dispatch(ACTION_SET_CURRENT_YEAR_OF_REALISATION, null);
                this.$router.push({path: '/'}).catch(err => {
                    // Ignore the vuex err regarding navigating to the page they are already on.
                    if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
                        console.log(err);
                    }
                });
            }, IDLE_TIME_IN_SECONDS * 1000);

            if (this.$store.state.isSmallIdle) {
                this.$store.dispatch(ACTION_SET_SMALL_IDLE, false);
            }
            this.smallIdle = setTimeout(() => {
                this.$store.dispatch(ACTION_SET_SMALL_IDLE, true);
                this.preloadImages();
            }, 1000);
        },

        showFilter() {
            this.$idleGrid.removeClass(this.openClass);
            setTimeout(() => {
                this.$idleGrid.addClass(this.hiddenClass);
                this.$filterGrid.removeClass(this.hiddenClass);
                setTimeout(() => {
                    this.$filterGrid.addClass(this.openClass);
                }, 100);
            }, 500);
        },

        showIdle() {
            this.$filterGrid.removeClass(this.openClass);
            setTimeout(() => {
                this.$filterGrid.addClass(this.hiddenClass);
                this.$idleGrid.removeClass(this.hiddenClass);
                setTimeout(() => {
                    this.$idleGrid.addClass(this.openClass);
                }, 100);
            }, 400);
        },

        async preloadImages() {
            if (this.$store.state.isSmallIdle) {
                const nextImage = this.getNextImage();

                if (nextImage !== undefined) {
                    try {
                        await this.loadImage(nextImage.imgObject.projectVisual[0].hq);
                        await this.loadImage(nextImage.imgObject.projectCadDrawing[0].hq);

                        this.markImageAsLoaded(nextImage, true);
                    } catch (err) {
                        this.markImageAsLoaded(nextImage, false);
                    }
                }
            }
        },

        getNextImage() {
            const imgObject = this.$store.state.projects.find(item => item.isPreLoaded === false);
            if (imgObject !== undefined) {
                const imgIndex = this.$store.state.projects.findIndex(i => i.slug === imgObject.slug);
                return {imgIndex, imgObject};
            }
        },

        loadImage(url) {
            return new Promise(function(resolve, reject) {
                let img = new Image();
                img.onload = function() {
                    setTimeout(() => {
                        resolve(url);
                    }, 1);
                };
                img.onerror = function() {
                    reject(url);
                };
                img.src = url;
            });
        },

        markImageAsLoaded(nextImage, success) {
            if (success) {
                //set isPreLoaded to true to mark as done
                this.$store
                    .dispatch(ACTION_TOGGLE_PRELOAD_IMAGE, {
                        key: nextImage.imgIndex,
                        isPreLoaded: true,
                    })
                    .then(() => {
                        this.preloadImages();
                    });
            } else {
                //set isPreLoaded to failed to skip
                this.$store
                    .dispatch(ACTION_TOGGLE_PRELOAD_IMAGE, {
                        key: nextImage.imgIndex,
                        isPreLoaded: 'failed',
                    })
                    .then(() => {
                        this.preloadImages();
                    });
            }
            this.imagesPreloadPercentage = Math.floor(
                (this.$store.state.projects.filter(item => item.isPreLoaded !== false).length / this.$store.state.projects.length) * 100,
            );
        },
    },
};
</script>

<style lang="scss">
@import 'scss/main';

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
    opacity: 0;
}
</style>
