<template>
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 22.8 30.5">
        <g id="placeholder" transform="translate(-82.18 -746.451)">
            <g transform="translate(82.68 741.951)">
                <path
                    fill="currentColor"
                    d="M10.9,34.5c-0.4,0-0.8-0.2-1-0.6c-0.1-0.1-8.5-12.6-8.5-12.7c-3-5.3-1-12,4.3-14.9
			C7.3,5.5,9.1,5,10.9,5l0,0c6,0,10.9,4.9,10.9,10.9c0,1.8-0.5,3.6-1.3,5.2c0,0.1-2.6,3.8-4.8,7.2c-1.9,2.8-3.6,5.4-3.7,5.6
			C11.7,34.2,11.3,34.5,10.9,34.5z M10.9,11.5c-2.4,0-4.4,1.9-4.4,4.3s1.9,4.4,4.3,4.4s4.4-1.9,4.4-4.3l0,0
			C15.2,13.5,13.3,11.5,10.9,11.5L10.9,11.5z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'MarkerIcon',
};
</script>
