<template>
    <div class="filter-grid grid js--list anim--open" :class="getClasses">
        <transition name="fade">
            <div class="no-results" v-show="this.$store.getters.getFilteredProjects.length === 0">
                <h1>{{ $store.getters.translate('no-matching-references-found') }}</h1>
            </div>
        </transition>

        <router-link v-for="project in $store.getters.getFilteredProjects" :to="`/${project.slug}`" :key="project.slug" class="item js--item">
            <div class="header">
                <div v-if="needsLargeThumb && $root.isOnline" class="image" :style="`background-image:url('${project.projectVisual[0].thumbLarge}')`"></div>
                <div v-if="needsLargeThumb && !$root.isOnline" class="image" :style="`background-image:url('${project.projectVisual[0].hq}')`"></div>
                <div v-if="!needsLargeThumb" class="image" :style="`background-image:url('${project.projectVisual[0].thumb}')`"></div>
                <p class="title">{{ project.title }}</p>
            </div>
            <div class="content">
                <p class="client">
                    <span class="name">{{ project.customer[0].title }}</span>
                    <span class="sep">|</span>
                    <span class="year">{{ project.yearOfRealization }}</span>
                </p>
                <p class="location">
                    <span class="badg" :style="`background-color: ${project.vehicleType[0].backgroundColor}`">
                        {{ project.vehicleType[0].title }}
                    </span>

                    <marker-icon class="mr-2" height="14" />
                    {{ locationString(project) }}
                </p>
            </div> </router-link
        ><!-- /item-->

        <!--fill with empty items-->
        <div class="item item__empty" v-for="item in [1, 2, 3, 4, 5, 6]" v-show="$store.getters.getFilteredProjects.length < 4" :key="item">
            <img
                v-show="$store.getters.getFilteredProjects.length === 0"
                src="assets/images/filler.png"
                alt=""
                style="padding-bottom: 8px; width: 100%; height: 294px;"
            />
        </div>
    </div>
    <!-- /filter-grid -->
</template>

<script>
import MarkerIcon from '@/components/icons/MarkerIcon';
export default {
    name: 'FilterGrid',
    components: {MarkerIcon},
    data() {
        return {
            $grid: null,
        };
    },

    mounted() {
        // eslint-disable-next-line no-undef
        this.$grid = $('.filter-grid');

        this.preloadImages();
    },

    computed: {
        needsLargeThumb() {
            return this.$store.getters.getFilteredProjects.length <= 3;
        },

        getClasses() {
            let animOpen = ''; //this.delayedIsIdle ? "" : "anim--open ";

            if (this.$grid) {
                // eslint-disable-next-line no-undef
                $(this.$grid).animate(
                    {
                        scrollTop: 0,
                    },
                    600,
                );
            }

            if (!this.needsLargeThumb) {
                return animOpen + 'grid__oneUp grid__threeUp';
            } else if (this.$store.getters.getFilteredProjects.length > 1) {
                return animOpen + 'grid__oneUp';
            } else if (this.$store.getters.getFilteredProjects.length === 0) {
                return animOpen + 'grid__oneUp';
            } else {
                return animOpen + 'grid__one';
            }
        },
    },

    asyncComputed: {
        //keep data for a second before removing, to bind image
        delayedIsIdle() {
            const delay = this.$store.state.isIdle ? 0 : 1000;
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(this.$store.state.isIdle);
                }, delay);
            });
        },
    },

    methods: {
        locationString(project) {
            if (project.location.city) {
                return this.$store.getters.getLocalisedCountryTitle(project.location.country) + ', ' + project.location.city;
            } else {
                return this.$store.getters.getLocalisedCountryTitle(project.location.country);
            }
        },
        preloadImages() {
            /*const stateProjects = this.$store.state.projects;

				const chunk = (array, batchSize = 10) => {
					const chunked = [];
					for(let i = 0; i < array.length; i += batchSize) {
						chunked.push(array.slice(i, i + batchSize))
					}

					return chunked;
				};

				console.log(chunk([...stateProjects]))*/
        },
        // prefetch(){
        // 	// const preLoad = this.$store.state.projects.find(item => item.slug === goToSlug.slice(1)).projectVisual[0].hq;
        // 	//console.log(goToSlug);
        // },
    },
};
</script>
