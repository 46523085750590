<template>
    <div style="width: 100%; height: 100%;">
        <gmap-map
            style="width: 100%; height: 100%; display: block"
            ref="map"
            :center="center"
            :zoom="2"
            :options="{
                disableDefaultUI: true,
                zoomControl: true,
                styles,
                maxZoom: 12,
                minZoom: 2,
                restriction: {
                    latLngBounds: {
                        // https://stackoverflow.com/questions/58762631/javascript-google-map-empty-gray-background-when-zoomed-to-zero
                        north: (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI,
                        south: (-Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI,
                        west: -180,
                        east: 180,
                    },
                    strictBounds: true,
                },
            }"
        >
            <gmap-cluster
                ref="mapCluster"
                :styles="[
                    {
                        url: 'assets/images/map/m2.png',
                        height: 50,
                        width: 50,
                        anchorText: [0, 0],
                        textColor: '#fff',
                        textSize: 13,
                        fontFamily: 'Open Sans, Segoe UI, sans-serif',
                    },
                ]"
                @click="onClusterClick"
                :zoomOnClick="true"
            >
                <gmap-marker
                    v-for="m in markers"
                    icon="assets/images/map/pin1.png"
                    :width="23"
                    :height="31"
                    :key="m.id"
                    :options="{id: m.id}"
                    :position.sync="m.position"
                    :clickable="true"
                    :draggable="false"
                    :animation="2"
                    @click="onMarkerClick(m.id)"
                ></gmap-marker>
            </gmap-cluster>
        </gmap-map>
    </div>
</template>

<script>
//https://www.npmjs.com/package/vue2-google-maps
import * as VueGoogleMaps from 'vue2-google-maps';
import {loaded} from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';

import Vue from 'vue';
import {
    ACTION_SET_MAP_AS_FILTER,
    ACTION_SET_PROJECTS_WITHIN_BOUNDS,
    SET_CURRENT_COUNTRY,
    SET_CURRENT_CUSTOMER,
    SET_CURRENT_VEHICLE_TYPE,
    SET_CURRENT_YEAR_OF_REALISATION,
    SET_MAP_AS_FILTER,
    SET_PROJECTS,
} from '../store';

Vue.component('GmapCluster', GmapCluster);

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCguNYujRC8O6TchljHzCV2e607ndBOQYA',
    },
});

export default {
    name: 'IgwMap',

    data() {
        return {
            lastValidCenter: null,
            styles: [
                {
                    featureType: 'administrative',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#6f6f6f'}],
                },
                {
                    featureType: 'administrative.country',
                    elementType: 'geometry.fill',
                    stylers: [{visibility: 'on'}],
                },
                {
                    featureType: 'administrative.country',
                    elementType: 'geometry.stroke',
                    stylers: [{color: '#649fbb'}, {visibility: 'on'}, {weight: '1'}],
                },
                {
                    featureType: 'administrative.country',
                    elementType: 'labels',
                    stylers: [{color: '#2d4275'}, {weight: '0.01'}, {visibility: 'off'}],
                },
                {
                    featureType: 'administrative.country',
                    elementType: 'labels.text.fill',
                    stylers: [{visibility: 'on'}],
                },
                {
                    featureType: 'administrative.country',
                    elementType: 'labels.text.stroke',
                    stylers: [{visibility: 'off'}, {weight: '0.01'}],
                },
                {
                    featureType: 'administrative.country',
                    elementType: 'labels.icon',
                    stylers: [{visibility: 'off'}],
                },
                {
                    featureType: 'landscape',
                    elementType: 'all',
                    stylers: [{color: '#f2f2f2'}],
                },
                {
                    featureType: 'poi',
                    elementType: 'all',
                    stylers: [{visibility: 'off'}],
                },
                {
                    featureType: 'road',
                    elementType: 'all',
                    stylers: [{saturation: -100}, {lightness: 45}],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'all',
                    stylers: [{visibility: 'simplified'}],
                },
                {
                    featureType: 'road.arterial',
                    elementType: 'labels.icon',
                    stylers: [{visibility: 'off'}],
                },
                {
                    featureType: 'transit',
                    elementType: 'all',
                    stylers: [{visibility: 'off'}],
                },
                {
                    featureType: 'water',
                    elementType: 'all',
                    stylers: [{color: '#bbd9ec'}, {visibility: 'on'}],
                },
            ],
            center: {
                lat: 50.0,
                lng: 5.0,
            },
            fitBoundsIid: 0,
            iidUpdateBounds: 0,
            iidFitBounds: 0,
        };
    },
    computed: {
        markers() {
            return this.$store.getters.getFilteredProjects.map(item => {
                return {
                    id: item.slug,
                    position: {lat: parseFloat(item.location.latitude), lng: parseFloat(item.location.longitude)},
                    animation: 2, //drop
                };
            });
        },
    },

    created() {
        loaded.then(() => {
            setTimeout(() => {
                // this.fitBounds();
                this.setMapListeners();
                // this.$store.dispatch(ACTION_SET_MAP, this.$refs.map.$mapObject);

                this.updateProjectsWithinBounds();
            }, 400);

            this.$store.subscribe(mutation => {
                if (mutation.type === SET_PROJECTS) {
                    //this.fitBounds();
                    this.fitBoundsIid = setTimeout(() => {
                        this.fitBounds();
                    }, 800);
                }
                if (
                    mutation.type === SET_CURRENT_VEHICLE_TYPE ||
                    mutation.type === SET_CURRENT_COUNTRY ||
                    mutation.type === SET_CURRENT_CUSTOMER ||
                    mutation.type === SET_CURRENT_YEAR_OF_REALISATION
                ) {
                    clearTimeout(this.fitBoundsIid);
                    this.fitBoundsIid = setTimeout(() => {
                        if (!this.$store.getters.useMapAsFilter) {
                            this.fitBounds();
                        }
                    }, 100);
                }

                if (mutation.type === SET_MAP_AS_FILTER) {
                    if (!this.$store.state.useMapAsFilter) {
                        this.fitBounds();
                    }
                }
            });
        });
    },

    methods: {
        setMapListeners() {
            window.google.maps.event.addListener(this.$refs.map.$mapObject, 'mouseup', () => {
                //console.log("mouseup");
                this.$store.dispatch(ACTION_SET_MAP_AS_FILTER, true);
            });

            window.google.maps.event.addListener(this.$refs.map.$mapObject, 'zoom_changed', () => {
                //console.log("zoom change", this.$refs.mapCluster.$clusterObject);
                //window.tmp = this.$refs.mapCluster.$clusterObject
                this.$refs.mapCluster.$clusterObject.resetViewport_();
                setTimeout(() => this.$refs.mapCluster.$clusterObject.redraw_(), 250);

                //this.$clusterObject.repaint();
            });

            window.google.maps.event.addListener(this.$refs.map.$mapObject, 'idle', () => {
                this.updateProjectsWithinBounds();
            });
        },

        checkLatitude(map) {
            let bounds = map.getBounds();
            let sLat = bounds.getSouthWest().lat();
            let nLat = bounds.getNorthEast().lat();
            if (sLat < -85 || nLat > 85) {
                //the map has gone beyond the world's max or min latitude - gray areas are visible
                //return to a valid position
                if (this.lastValidCenter) {
                    map.setCenter(this.lastValidCenter);
                }
            } else {
                this.lastValidCenter = map.getCenter();
            }
        },

        fitBounds() {
            if (!this.$store.getters.useMapAsFilter && this.$refs.map) {
                clearTimeout(this.iidFitBounds);
                this.iidFitBounds = setTimeout(() => {
                    // console.log("fitBounds");
                    if (this.markers.length > 0) {
                        let bounds = new window.google.maps.LatLngBounds();
                        this.markers.forEach(marker => {
                            bounds.extend({
                                lat: marker.position.lat,
                                lng: marker.position.lng,
                            });
                        });
                        if (this.$refs.map) {
                            this.$refs.map.fitBounds(bounds);
                        }
                    } else {
                        this.$refs.map.$mapObject.setZoom(2);
                    }
                }, 200);
            }
        },
        onMarkerClick(slug) {
            this.$router.push('/' + slug);
        },
        onClusterClick() {},

        updateProjectsWithinBounds() {
            clearTimeout(this.iidUpdateBounds);

            this.iidUpdateBounds = setTimeout(() => {
                if (this.$refs.map) {
                    const bounds = this.$refs.map.$mapObject.getBounds();
                    if (bounds) {
                        //console.log("Gert: updateProjectsWithinBounds");
                        const withinBounds = this.$store.state.projects.filter(item =>
                            bounds.contains({
                                lat: parseFloat(item.location.latitude),
                                lng: parseFloat(item.location.longitude),
                            }),
                        );
                        this.$store.dispatch(ACTION_SET_PROJECTS_WITHIN_BOUNDS, withinBounds);

                        this.checkLatitude(this.$refs.map.$mapObject);
                    }
                }
            }, 200);
        },
    },
};
</script>
